<template>
  <div v-loading="loading" class="confirm-email">
    <el-card v-if="!loading" class="confirm-email__card">
      <h6 class="confirm-email__title">{{ $t('linkExpired') }}</h6>
      <el-button class="confirm-email__button" :disabled="resendDisabled" @click="handleResend">{{
        $t('resendLink')
      }}</el-button>
    </el-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ConfirmEmail',

  metaInfo() {
    return {
      title: this.$i18n.t('title'),
    }
  },

  data() {
    return {
      loading: true,
      showResend: false,
      resendDisabled: false,
    }
  },

  async created() {
    const { token } = this.$route.params
    if (!token) {
      this.$router.push('/')
    } else {
      try {
        await this.$axios.put(`/api/email/confirm/${token}`)
        this.$router.push('/')
        this.$notify({
          type: 'success',
          title: this.$i18n.t('emailConfirmed'),
        })
      } catch (err) {
        if (err.status === 400) {
          this.showResend = true
        } else {
          this.$router.push('/')
        }
      }
    }
  },

  methods: {
    ...mapActions('user', ['sendEmailConfirmation']),
    async handleResend() {
      const { error } = await this.sendEmailConfirmation(this.formData)

      if (error) {
        this.resendDisabled = true
        return false
      } else {
        this.$router.push('/')
        this.$notify({
          type: 'success',
          title: this.$i18n.t('messages.success'),
          message: this.$i18n.t('emailSent'),
        })
      }
    },
  },
}
</script>

<i18n>
{
  "en": {
    "title": "Email confirmation",
    "emailConfirmed": "Email address confirmed successfully!",
    "linkExpired": "Email confirmation link has expired",
    "resendLink": "Resend link",
    "emailSent": "Confirmation email has been sent to your email address"
  },
  "ru": {
    "title": "Подтверждение почты",
    "emailConfirmed": "Адрес электронной почты успешно подтвержден!",
    "linkExpired": "Ссылка для подтверждения адреса электронной почты более не действительна",
    "resendLink": "Отправить заново",
    "emailSent": "Письмо со ссылкой для подтверждения адреса электронной почты отправлено на вашу электронную почту"
  }
}
</i18n>

<style lang="scss">
.confirm-email {
  height: 100%;
  background: $color-primary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.confirm-email__card {
  max-width: 400px;
  display: flex;
  justify-content: center;
}
.confirm-email__title {
  margin-bottom: 12px;
}
.confirm-email__button {
  width: 100%;
}
</style>
